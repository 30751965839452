import { inject } from "@angular/core";
import { CanActivateFn, Router, UrlTree } from "@angular/router";
import { PermissionsEnum } from "@app/_auth/_enums/permissions.enum";
import { ROUTER_REDIRECT_URL } from "@app/_auth/injection-tokens/router-redirect-url";
import { AuthService } from "@app/_auth/services/auth.service";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";

export const permissionsGuard: (
    permissions: PermissionsEnum[],
) => CanActivateFn = (permissions: PermissionsEnum[]) => {
    return function (): Observable<true | UrlTree> {
        const authService = inject(AuthService);
        const router = inject(Router);
        const routerRedirectUrl = inject(ROUTER_REDIRECT_URL);

        return authService.loggedIn$.pipe(
            take(1),
            map(() => authService.hasSomeOfPermissions(permissions)),
            map((canActivate) => {
                if (canActivate) {
                    return true;
                } else {
                    const redirectTo = routerRedirectUrl + location.search;
                    return router.parseUrl(redirectTo);
                }
            }),
        );
    };
};
