import { inject, Injectable } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import {
    ActionHandler,
    initActionHandlers,
    WithActionHandlers,
} from "ngx-action";

export namespace MetaActions {
    export class SetDescription {
        constructor(public value: string) {}
    }

    export class ResetDescription {}
}

@WithActionHandlers()
@Injectable({ providedIn: "root" })
export class MetaActionHandlers {
    private readonly meta = inject(Meta);

    constructor() {
        initActionHandlers(this);
    }

    @ActionHandler(MetaActions.SetDescription)
    private metaSetDescriptionActionHandler(
        action: MetaActions.SetDescription,
    ): void {
        this.meta.addTag({
            name: "description",
            content: action.value,
        });
    }

    @ActionHandler(MetaActions.ResetDescription)
    private metaResetDescriptionActionHandler(): void {
        this.meta.removeTag("name=description");
    }
}
