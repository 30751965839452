import { Observable } from "rxjs";

export function extendGlobals(): void {
    addObservableValueGetter();
}

function addObservableValueGetter(): void {
    Object.defineProperty(Observable.prototype, "value", {
        get<T>(): T {
            let value: T;
            let hasValue = false;
            const subscription = this.subscribe((v: T) => {
                value = v;
                hasValue = true;
            });
            subscription.unsubscribe();
            if (!hasValue) {
                throw new Error(`Observable doesn't have a value.`);
            }
            return value!;
        },
    });
}
