import { NgClass } from "@angular/common";
import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { BaseButtonComponent } from "@app/_infrastructure/base-classes/base-button.component";
import { ButtonLoadingIndicatorComponent } from "@app/_infrastructure/components/button-loading-indicator/button-loading-indicator.component";

@Component({
    selector: "app-icon-text-button",
    templateUrl: "./icon-text-button.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatButtonModule, NgClass, ButtonLoadingIndicatorComponent],
})
export class IconTextButtonComponent extends BaseButtonComponent {
    @HostBinding("class") private readonly hostClass = "app-button-wrapper";

    @Input() public icon = "";
    @Input() public text = "";
}
