export const AUTH_WEB_PATHS = {
    root: "auth",
    signIn: "sign-in",
};

export const AUTH_WEB_ROUTES = {
    signIn: () => "/auth/sign-in",
};

export const AUTH_API_ROUTES = {
    signIn: () => "/auth/sign-in",
    signOut: () => "/auth/sign-out",
    userDetails: () => "/auth/user",
};
