import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { sharedImports } from "@app/_infrastructure/shared-imports";
import { CategoryListItemViewModel } from "@app/category/_models/category-list-item.view-model";

@Component({
    selector: "app-category-list-item",
    templateUrl: "./category-list-item.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [sharedImports],
})
export class CategoryListItemComponent {
    @Input({ required: true }) public listItem!: CategoryListItemViewModel;
}
