import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogButtonComponent } from "@app/_infrastructure/components/buttons/dialog-button/dialog-button.component";

@Component({
    selector: "app-confirm",
    templateUrl: "./confirm.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [DialogButtonComponent],
})
export class ConfirmComponent {
    protected readonly title: string = inject(MAT_DIALOG_DATA);
}
