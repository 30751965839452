import { DestroyRef, inject, Injectable } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ApiErrorsSummary } from "@app/_api/_models/api-errors.summary";
import { ApiErrorActions } from "@app/_infrastructure/action-handlers/api-error.action-handlers";
import {
    OptimizedBehaviorSubject,
    skipErrors,
} from "@app/_infrastructure/rxjs";
import { Actions } from "ngx-action";
import {
    BehaviorSubject,
    exhaustMap,
    mergeMap,
    Observable,
    of,
    Subject,
    tap,
    throwError,
} from "rxjs";
import { catchError, finalize, map } from "rxjs/operators";

@Injectable()
export abstract class BaseSender<Payload = void, Response = unknown> {
    private readonly destroyRef = inject(DestroyRef);

    private readonly dispatch$ = new Subject<Payload>();
    private readonly dispatchingSubject$ = new OptimizedBehaviorSubject(false);
    private readonly dispatchingSet = new Set<string | number>();

    public readonly dispatching$ = this.dispatchingSubject$;
    public readonly dispatchingSet$ = new BehaviorSubject(this.dispatchingSet);

    // eslint-disable-next-line deprecation/deprecation
    protected readonly mapOperator = exhaustMap;

    constructor() {
        this.subscribeOnDispatch();
    }

    private subscribeOnDispatch(): void {
        this.dispatch$
            .pipe(
                this.mapOperator((payload) =>
                    this.confirmObservable(payload).pipe(
                        tap(() => {
                            this.setDispatching(payload, true);
                        }),
                        mergeMap(() => this.dispatchObservable(payload)),
                        catchError((apiErrorsSummary: ApiErrorsSummary) => {
                            this.onDispatchError(apiErrorsSummary);
                            return throwError(() => undefined);
                        }),
                        skipErrors(),
                        map((response) => [response, payload] as const),
                        finalize(() => {
                            this.setDispatching(payload, false);
                        }),
                    ),
                ),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe(([response, payload]) => {
                this.onDispatchSuccess(response, payload);
            });
    }

    public dispatch(payload: Payload): void {
        this.dispatch$.next(payload);
    }

    private setDispatching(payload: Payload, dispatching: boolean): void {
        const dispatchingKey = this.mapPayloadToDispatchingKey(payload);
        if (dispatchingKey) {
            this.updateDispatchingSet(dispatchingKey, dispatching);
        }
        this.dispatchingSubject$.next(dispatching);
    }

    private updateDispatchingSet(
        key: string | number,
        dispatching: boolean,
    ): void {
        if (dispatching) {
            this.dispatchingSet.add(key);
        } else {
            this.dispatchingSet.delete(key);
        }
        this.dispatchingSet$.next(this.dispatchingSet);
    }

    protected mapPayloadToDispatchingKey(
        payload: Payload,
    ): string | number | void {
        if (
            payload &&
            (typeof payload === "string" || typeof payload === "number")
        ) {
            return payload;
        }
    }

    protected confirmObservable(payload: Payload): Observable<unknown> {
        return of(payload);
    }

    protected abstract dispatchObservable(
        payload: Payload,
    ): Observable<Response>;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected onDispatchSuccess(response: Response, payload: Payload): void {}

    protected onDispatchError(apiErrorsSummary: ApiErrorsSummary): void {
        Actions.dispatch(new ApiErrorActions.Handle(apiErrorsSummary));
    }
}
