import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    inject,
    Input,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "app-dialog-button",
    templateUrl: "./dialog-button.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatButtonModule],
})
export class DialogButtonComponent {
    private readonly matDialogRef = inject(MatDialogRef<unknown>);

    @HostBinding("class") private readonly hostClass = "app-button-wrapper";

    @Input() public text = "Закрыть";
    @Input() public result: unknown;

    protected close(): void {
        this.matDialogRef.close(this.result);
    }

    protected preventDialogReopenOnLongKeyPress(event: Event): void {
        event.preventDefault();
    }
}
