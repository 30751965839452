import { ChangeDetectionStrategy, Component, HostBinding } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@Component({
    selector: "app-loading-state",
    templateUrl: "./loading-state.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatProgressSpinnerModule],
})
export class LoadingStateComponent {
    @HostBinding("class") private readonly hostClass =
        "flex justify-center items-center w-full h-full min-h-52-app animate-pulse";
}
