import { inject, Injectable } from "@angular/core";
import { Empty } from "@app/_infrastructure/_models/empty";
import { NotificationActions } from "@app/_infrastructure/action-handlers/notification.action-handlers";
import { BaseSender } from "@app/_infrastructure/base-classes/base.sender";
import { DialogService } from "@app/_infrastructure/services/dialog.service";
import { CategoryDeletePayload } from "@app/category/_models/category-delete.payload";
import { CategoryActions } from "@app/category/category.actions";
import { CategoryApiService } from "@app/category/category.api-service";
import { Actions } from "ngx-action";
import { mergeMap, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class CategoryDeleteSender extends BaseSender<CategoryDeletePayload> {
    private readonly apiService = inject(CategoryApiService);
    private readonly dialogService = inject(DialogService);

    // eslint-disable-next-line deprecation/deprecation
    protected override readonly mapOperator = mergeMap;

    protected override confirmObservable(
        payload: CategoryDeletePayload,
    ): Observable<unknown> {
        return this.dialogService.confirmOrStop(
            `Удалить категорию '${payload.name}'?`,
        );
    }

    protected override mapPayloadToDispatchingKey(
        payload: CategoryDeletePayload,
    ): number {
        return payload.id;
    }

    protected dispatchObservable(
        payload: CategoryDeletePayload,
    ): Observable<Empty> {
        return this.apiService.delete(payload.id);
    }

    protected override onDispatchSuccess(
        response: Empty,
        payload: CategoryDeletePayload,
    ): void {
        Actions.dispatch(new CategoryActions.DeleteSuccess(payload.id));
        Actions.dispatch(new NotificationActions.Warning("Категория удалена"));
    }
}
