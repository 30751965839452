import { ComponentType } from "@angular/cdk/overlay";
import { inject, Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DialogConfig } from "@app/_infrastructure/_models/dialog.config";
import { ConfirmComponent } from "@app/_infrastructure/components/confirm/confirm.component";
import { EMPTY, iif, Observable, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class DialogService {
    private readonly matDialog = inject(MatDialog);

    public open<TComponent, TData, TResult>(
        component: ComponentType<TComponent>,
        config?: DialogConfig<TData>,
    ): Observable<TResult> {
        return this.matDialog
            .open(component, {
                autoFocus: ".app-dialog-actions button",
                ...config,
            })
            .afterClosed();
    }

    public confirm(html: string): Observable<boolean> {
        return this.open<ConfirmComponent, string, boolean | undefined>(
            ConfirmComponent,
            {
                data: html,
            },
        ).pipe(map((value) => !!value));
    }

    public confirmOrStop(html: string): Observable<boolean> {
        return this.confirm(html).pipe(
            mergeMap((confirmed) => iif(() => confirmed, of(confirmed), EMPTY)),
        );
    }
}
