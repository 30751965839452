import { Routes } from "@angular/router";
import { InfoComponent } from "@app/info/info.component";
import { INFO_WEB_PATHS } from "@app/info/info.routes-factories";

export const infoRoutes: Routes = [
    {
        path: INFO_WEB_PATHS.root,
        component: InfoComponent,
    },
];
