import { inject, Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NotificationConfig } from "@app/_infrastructure/_models/notification.config";
import {
    ActionHandler,
    initActionHandlers,
    WithActionHandlers,
} from "ngx-action";

export namespace NotificationActions {
    class Base {
        constructor(
            public text: string,
            public config?: NotificationConfig,
        ) {}
    }

    export class Success extends Base {}

    export class Warning extends Base {}

    export class Error extends Base {}
}

@WithActionHandlers()
@Injectable({ providedIn: "root" })
export class NotificationActionHandlers {
    private readonly matSnackBar = inject(MatSnackBar);

    constructor() {
        initActionHandlers(this);
    }

    @ActionHandler(NotificationActions.Success)
    private notificationSuccessActionHandler(
        action: NotificationActions.Success,
    ): void {
        this.matSnackBar.open(
            action.text,
            action.config?.actionText || "Закрыть",
            {
                verticalPosition: "bottom",
                panelClass: "app-bg-success",
                ...action.config,
            },
        );
    }

    @ActionHandler(NotificationActions.Warning)
    private notificationWarningActionHandler(
        action: NotificationActions.Warning,
    ): void {
        this.matSnackBar.open(
            action.text,
            action.config?.actionText || "Закрыть",
            {
                verticalPosition: "bottom",
                panelClass: "app-bg-warning",
                ...action.config,
            },
        );
    }

    @ActionHandler(NotificationActions.Error)
    private notificationErrorActionHandler(
        action: NotificationActions.Error,
    ): void {
        this.matSnackBar.open(
            action.text,
            action.config?.actionText || "Закрыть",
            {
                verticalPosition: "bottom",
                panelClass: "app-bg-error",
                ...action.config,
            },
        );
    }
}
