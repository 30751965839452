@if (mobileLayout$ | async) {
    <app-icon-text-button
        class="mr-1"
        icon="icon-menu"
        text="Меню"
        (btnClick)="toggleSidebar()"
    />
}

@if (showTitle$ | async) {
    <div class="mr-2 font-bold leading-none truncate">
        {{ appTitle }}
    </div>
}

<div class="flex-auto"></div>

@if (loggedIn$ | async) {
    <app-icon-button
        icon="icon-logout"
        tooltip="Выйти"
        [loading]="(authSignOutSender.dispatching$ | async)!"
        (btnClick)="authSignOutSender.dispatch()"
    />
} @else {
    <app-icon-button-as-link
        [href]="signInUrl"
        icon="icon-login"
        tooltip="Войти"
    />
}
