<app-button-loading-indicator [loading]="loading">
    <button
        class="app-base-icon-button"
        type="button"
        mat-button
        [disabled]="disabled || loading"
        (click)="onClick($event)"
    >
        <i [ngClass]="icon"></i>
        {{ text }}
    </button>
</app-button-loading-indicator>
