import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { RouterOutlet } from "@angular/router";
import { ApiResponse } from "@app/_api/_models/api.response";
import { AuthService } from "@app/_auth/services/auth.service";
import { TabSyncTypesEnum } from "@app/_infrastructure/_enums/tab-sync-types.enum";
import { NotificationActions } from "@app/_infrastructure/action-handlers/notification.action-handlers";
import { TitleActions } from "@app/_infrastructure/action-handlers/title.action-handlers";
import { SignInTabSyncPayload } from "@app/_tab-sync/_models/sign-in.tab-sync-payload";
import { TabSyncService } from "@app/_tab-sync/tab-sync.service";
import { AuthResponse } from "@app/auth/_models/auth.response";
import { AuthApiService } from "@app/auth/auth.api-service";
import { Actions } from "ngx-action";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent {
    private readonly apiService = inject(AuthApiService);
    private readonly authService = inject(AuthService);
    private readonly tabSyncService = inject(TabSyncService);

    constructor() {
        Actions.dispatch(new TitleActions.Reset());
        this.initUser();
        this.subscribeOnOtherTabSignIn();
        this.subscribeOnOtherTabSignOut();
    }

    private initUser(): void {
        this.apiService
            .userDetails()
            .pipe(takeUntilDestroyed())
            .subscribe({
                next: (response) => this.onUserDetailsSuccess(response),
                error: () => this.onUserDetailsError(),
            });
    }

    private onUserDetailsSuccess(
        response: ApiResponse<AuthResponse | undefined>,
    ): void {
        if (response.data) {
            this.authService.signIn(response.data);
        } else {
            this.authService.signOut();
        }
    }

    private onUserDetailsError(): void {
        this.authService.signOut();
    }

    private subscribeOnOtherTabSignIn(): void {
        this.tabSyncService
            .onMessage<SignInTabSyncPayload>(TabSyncTypesEnum.SignIn)
            .pipe(takeUntilDestroyed())
            .subscribe((message) => this.onOtherTabSignIn(message));
    }

    private onOtherTabSignIn(message: SignInTabSyncPayload): void {
        Actions.dispatch(new NotificationActions.Success("Выполнен вход"));
        this.authService.signIn(message.payload);
    }

    private subscribeOnOtherTabSignOut(): void {
        this.tabSyncService
            .onMessage(TabSyncTypesEnum.SignOut)
            .pipe(takeUntilDestroyed())
            .subscribe(() => this.onOtherTabSignOut());
    }

    private onOtherTabSignOut(): void {
        this.authService.signOut();
    }
}
