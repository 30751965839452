import { Routes } from "@angular/router";
import { PermissionsEnum } from "@app/_auth/_enums/permissions.enum";
import { permissionsGuard } from "@app/_auth/guards/permissions.guard";
import { ADMIN_PANEL_WEB_PATHS } from "@app/admin-panel/admin-panel.routes-factories";

export const adminPanelRoutes: Routes = [
    {
        path: ADMIN_PANEL_WEB_PATHS.root,
        loadComponent: () => import("@app/admin-panel/admin-panel.component"),
        canActivate: [permissionsGuard([PermissionsEnum.PlantExport])],
    },
];
