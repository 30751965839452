import {
    provideHttpClient,
    withInterceptors,
    withXsrfConfiguration,
} from "@angular/common/http";

import "@angular/common/locales/global/ru-BY";
import { ErrorHandler, importProvidersFrom, LOCALE_ID } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import {
    MAT_SNACK_BAR_DEFAULT_OPTIONS,
    MatSnackBarModule,
} from "@angular/material/snack-bar";
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from "@angular/material/tooltip";
import { bootstrapApplication } from "@angular/platform-browser";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { provideRouter, withRouterConfig } from "@angular/router";
import { CustomCookiesEnum } from "@app/_api/_enums/custom-cookies.enum";
import { CustomHeadersEnum } from "@app/_api/_enums/custom-headers.enum";
import { apiProgressInterceptor } from "@app/_api/interceptors/api-progress.interceptor";
import { apiUrlInterceptor } from "@app/_api/interceptors/api-url.interceptor";
import { cacheInterceptor } from "@app/_api/interceptors/cache.interceptor";
import { convertNullUndefinedInterceptor } from "@app/_api/interceptors/convert-null-undefined.interceptor";
import { responseInterceptor } from "@app/_api/interceptors/response.interceptor";
import { ROUTER_REDIRECT_URL } from "@app/_auth/injection-tokens/router-redirect-url";
import { APP_LOCALE_ID } from "@app/_infrastructure/_constants/app-locale-id.const";
import { ApiErrorActionHandlers } from "@app/_infrastructure/action-handlers/api-error.action-handlers";
import { GoogleAnalyticsActionHandlers } from "@app/_infrastructure/action-handlers/google-analytics.action-handlers";
import { MetaActionHandlers } from "@app/_infrastructure/action-handlers/meta.action-handlers";
import { NotificationActionHandlers } from "@app/_infrastructure/action-handlers/notification.action-handlers";
import { TitleActionHandlers } from "@app/_infrastructure/action-handlers/title.action-handlers";
import { GlobalErrorHandlerService } from "@app/_infrastructure/services/global-error-handler.service";
import { webAnimationsApiSupported } from "@app/_infrastructure/utils/web-animations-api-supported";
import { AppComponent } from "@app/app.component";
import { appRoutes } from "@app/app.routes";
import { APP_WEB_PATHS } from "@app/app.routes-factories";
import { ActionsModule } from "ngx-action";
import { extendGlobals } from "src/extend-globals";

extendGlobals();

bootstrapApplication(AppComponent, {
    providers: [
        provideAnimationsAsync(
            webAnimationsApiSupported() ? "animations" : "noop",
        ),
        provideHttpClient(
            withXsrfConfiguration({
                cookieName: CustomCookiesEnum.Xsrf,
                headerName: CustomHeadersEnum.Xsrf,
            }),
            withInterceptors([
                // order is important: apiUrl -> cache -> apiProgress -> convertNullUndefined -> response
                apiUrlInterceptor,
                cacheInterceptor,
                apiProgressInterceptor,
                convertNullUndefinedInterceptor,
                responseInterceptor,
            ]),
        ),
        provideRouter(
            appRoutes,
            withRouterConfig({
                paramsInheritanceStrategy: "always",
            }),
        ),

        importProvidersFrom(MatSnackBarModule, MatDialogModule),
        importProvidersFrom(
            ActionsModule.provide([
                ApiErrorActionHandlers,
                GoogleAnalyticsActionHandlers,
                MetaActionHandlers,
                NotificationActionHandlers,
                TitleActionHandlers,
            ]),
        ),

        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: { duration: 10_000, verticalPosition: "top" },
        },
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
            useValue: { position: "above" },
        },
        { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
        { provide: LOCALE_ID, useValue: APP_LOCALE_ID },
        { provide: ROUTER_REDIRECT_URL, useValue: APP_WEB_PATHS.redirectTo },
    ],
}).catch((error: unknown) => console.error(error));
