<mat-sidenav-container>
    <mat-sidenav
        [autoFocus]="false"
        [disableClose]="(mobileLayout$ | async) === false"
        [mode]="(mode$ | async)!"
        [opened]="opened$ | async"
        (openedChange)="onOpenedChange($event)"
    >
        <app-category-list>
            <div class="app-sidenav-item">
                <a
                    class="app-sidenav-item-link"
                    [routerLink]="infoUrl"
                    routerLinkActive="app-sidenav-item-link-active"
                >
                    Информация
                    <i class="app-sidenav-item-icon icon-info"></i>
                </a>
            </div>

            <div
                class="app-sidenav-item"
                *appHasPermissions="[PermissionsEnum.PlantExport]"
            >
                <a
                    class="app-sidenav-item-link"
                    [routerLink]="adminPanelUrl"
                    routerLinkActive="app-sidenav-item-link-active"
                >
                    Панель Администратора
                    <i class="app-sidenav-item-icon icon-gauge"></i>
                </a>
            </div>

            <div
                class="app-sidenav-item"
                *appHasPermissions="[PermissionsEnum.CacheRead]"
            >
                <a
                    class="app-sidenav-item-link"
                    [routerLink]="cacheUrl"
                    routerLinkActive="app-sidenav-item-link-active"
                >
                    Кэш
                    <i class="app-sidenav-item-icon icon-wrench"></i>
                </a>
            </div>

            <div
                class="app-sidenav-item"
                *appHasPermissions="[PermissionsEnum.CategoryCreate]"
            >
                <a
                    class="app-sidenav-item-link"
                    [routerLink]="categoryCreateUrl"
                    routerLinkActive="app-sidenav-item-link-active"
                >
                    Создать Категорию
                    <i class="app-sidenav-item-icon icon-plus"></i>
                </a>
            </div>

            <div
                class="app-sidenav-item"
                *appHasPermissions="[PermissionsEnum.PlantCreate]"
            >
                <a
                    class="app-sidenav-item-link"
                    [routerLink]="plantCreateUrl"
                    routerLinkActive="app-sidenav-item-link-active"
                >
                    Создать Растение
                    <i class="app-sidenav-item-icon icon-plus"></i>
                </a>
            </div>
        </app-category-list>
    </mat-sidenav>

    <mat-sidenav-content #sidenavContent role="main">
        <router-outlet />
    </mat-sidenav-content>
</mat-sidenav-container>
