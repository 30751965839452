import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandlerFn,
    HttpRequest,
} from "@angular/common/http";
import { ApiError } from "@app/_api/_models/api-error";
import { ApiErrorResponse } from "@app/_api/_models/api-error.response";
import { ApiErrorsSummary } from "@app/_api/_models/api-errors.summary";
import { forkJoin, from, mergeMap, Observable, of, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

type ParsedErrorResponseBodyType = ApiErrorResponse | unknown | null;

export function responseInterceptor(
    request: HttpRequest<unknown>,
    next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
    return next(request).pipe(
        catchError((errorResponse: HttpErrorResponse) => {
            return forkJoin([
                of(errorResponse),
                parseErrorResponseBody(errorResponse.error),
            ]).pipe(
                mergeMap(
                    ([response, body]: [
                        HttpErrorResponse,
                        ParsedErrorResponseBodyType,
                    ]) => {
                        return mapErrorResponseToApiErrorsSummary(
                            response,
                            body,
                        );
                    },
                ),
            );
        }),
    );
}

function parseErrorResponseBody(
    body: Blob | ApiErrorResponse | unknown | null,
): Observable<ParsedErrorResponseBodyType> {
    return body instanceof Blob ? blobToObject(body) : of(body);
}

function blobToObject(blob: Blob): Observable<unknown> {
    return from(blob.text()).pipe(map((text) => JSON.parse(text)));
}

function mapErrorResponseToApiErrorsSummary(
    errorResponse: HttpErrorResponse,
    body: ParsedErrorResponseBodyType,
): Observable<HttpEvent<ApiErrorsSummary>> {
    const errors: ApiError[] | unknown = (body as ApiErrorResponse)?.errors;
    const apiErrors: ApiError[] = Array.isArray(errors) ? errors : [];

    if (apiErrors.length === 0) {
        apiErrors.push({ message: "Ошибка сервера или интернет-соединения" });
    }

    return throwError(
        () => new ApiErrorsSummary(errorResponse.status, apiErrors),
    );
}
