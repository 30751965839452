import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ApiProgressService {
    private readonly progressMap = new Map<symbol, number>();
    private readonly progressSubject$ = new BehaviorSubject<
        Map<symbol, number>
    >(this.progressMap);

    public setProgress(progressId: symbol, progress: number): void {
        // progress = [0...1]
        this.progressMap.set(progressId, progress);
        this.progressSubject$.next(this.progressMap);
    }

    public resetProgress(progressId: symbol): void {
        this.progressMap.delete(progressId);
        this.progressSubject$.next(this.progressMap);
    }

    public getProgress(id: symbol): Observable<number> {
        return this.progressSubject$.pipe(
            map((progressMap) => {
                return progressMap.get(id) ?? 0;
            }),
            distinctUntilChanged(),
        );
    }
}
