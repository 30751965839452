import { Routes } from "@angular/router";
import { PermissionsEnum } from "@app/_auth/_enums/permissions.enum";
import { permissionsGuard } from "@app/_auth/guards/permissions.guard";
import { canDeactivateGuard } from "@app/_infrastructure/guards/can-deactivate.guard";
import { APP_WEB_PATHS } from "@app/app.routes-factories";
import { CATEGORY_WEB_PATHS } from "@app/category/category.routes-factories";

export const categoryRoutes: Routes = [
    {
        path: CATEGORY_WEB_PATHS.root,
        children: [
            {
                path: APP_WEB_PATHS.create,
                loadComponent: () =>
                    import("@app/category/create/category-create.component"),
                canActivate: [
                    permissionsGuard([PermissionsEnum.CategoryCreate]),
                ],
                canDeactivate: [canDeactivateGuard()],
            },
            {
                path: `:${CATEGORY_WEB_PATHS.id}/${APP_WEB_PATHS.edit}`,
                loadComponent: () =>
                    import("@app/category/edit/category-edit.component"),
                canActivate: [
                    permissionsGuard([PermissionsEnum.CategoryUpdate]),
                ],
                canDeactivate: [canDeactivateGuard()],
            },
        ],
    },
];
