export enum PermissionsEnum {
    CategoryCreate = 2,

    CategoryRead = 3,

    CategoryUpdate = 4,

    CategoryDelete = 5,

    PlantCreate = 6,

    PlantRead = 7,

    PlantUpdate = 8,

    PlantDelete = 9,

    PlantExport = 10,

    CacheRead = 11,

    CacheDelete = 12,
}
