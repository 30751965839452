export enum HttpMethodsEnum {
    OPTIONS = "OPTIONS",

    GET = "GET",

    POST = "POST",

    PUT = "PUT",

    DELETE = "DELETE",
}
