export const CACHE_WEB_PATHS = {
    root: "cache",
    id: "plantId",
};

export const CACHE_WEB_ROUTES = {
    list: () => "/cache",
};

export const CACHE_API_ROUTES = {
    list: () => "/cache",
    delete: () => "/cache/delete",
};
