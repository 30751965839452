import { Routes } from "@angular/router";
import { PermissionsEnum } from "@app/_auth/_enums/permissions.enum";
import { permissionsGuard } from "@app/_auth/guards/permissions.guard";
import { CACHE_WEB_PATHS } from "@app/cache/cache.routes-factories";

export const cacheRoutes: Routes = [
    {
        path: CACHE_WEB_PATHS.root,
        loadComponent: () => import("@app/cache/list/cache-list.component"),
        canActivate: [permissionsGuard([PermissionsEnum.CacheRead])],
    },
];
