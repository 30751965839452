import { Injectable } from "@angular/core";
import { GtagEventsEnum } from "@app/_infrastructure/_enums/gtag-events.enum";
import {
    ActionHandler,
    initActionHandlers,
    WithActionHandlers,
} from "ngx-action";

export namespace GoogleAnalyticsActions {
    export class PageView {}

    export class Event {
        constructor(
            public name: GtagEventsEnum,
            public category: string,
            public label: string,
        ) {}
    }
}

@WithActionHandlers()
@Injectable({ providedIn: "root" })
export class GoogleAnalyticsActionHandlers {
    // GA scripts are added in index-html-transform.ts
    constructor() {
        initActionHandlers(this);
    }

    @ActionHandler(GoogleAnalyticsActions.PageView)
    private googleAnalyticsPageViewActionHandler(): void {
        /* eslint-disable @typescript-eslint/naming-convention */
        gtag("event", "page_view", {
            page_title: document.title,
            page_location: location.href,
            page_path: location.pathname + location.search,
        });
        /* eslint-enable @typescript-eslint/naming-convention */
    }

    @ActionHandler(GoogleAnalyticsActions.Event)
    private googleAnalyticsEventActionHandler(
        action: GoogleAnalyticsActions.Event,
    ): void {
        /* eslint-disable @typescript-eslint/naming-convention */
        gtag("event", action.name, {
            event_category: action.category,
            event_label: action.label,
        });
        /* eslint-enable @typescript-eslint/naming-convention */
    }
}
