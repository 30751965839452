<i class="text-6xl icon-attention mb-1 app-text-error"></i>

<div class="w-full font-bold text-center mb-1 app-text-error">
    Не удалось загрузить данные
</div>

<app-text-button
    class="app-text-button-primary"
    text="Обновить"
    (btnClick)="refresh()"
/>
