import { inject, Injectable } from "@angular/core";
import {
    NavigationEnd,
    NavigationExtras,
    Params,
    Router,
} from "@angular/router";
import { AppRouterState } from "@app/_infrastructure/_models/app-router-state";
import { NotificationActions } from "@app/_infrastructure/action-handlers/notification.action-handlers";
import { objectDeepClone } from "@app/_infrastructure/utils/object-deep-clone";
import { objectsEqual } from "@app/_infrastructure/utils/objects-equal";
import { Actions } from "ngx-action";
import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, filter, map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class AppRouterService {
    private readonly router = inject(Router);

    private readonly initialState = new AppRouterState({}, {}, "/");
    private readonly state$ = new BehaviorSubject<AppRouterState>(
        this.initialState,
    );

    private readonly params$ = this.state$.pipe(
        map((value) => value.params),
        distinctUntilChanged<Params>(objectsEqual),
    );
    private readonly queryParams$ = this.state$.pipe(
        map((value) => value.queryParams),
        distinctUntilChanged<Params>(objectsEqual),
    );
    public readonly pathname$ = this.state$.pipe(
        map((value) => value.pathname),
        distinctUntilChanged(),
    );

    constructor() {
        this.subscribeOnNavigationEnd();
    }

    private subscribeOnNavigationEnd(): void {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => this.parseRoute());
    }

    private parseRoute(): void {
        let route = this.router.routerState.snapshot.root;
        while (route.firstChild) {
            route = route.firstChild;
        }
        this.state$.next(
            new AppRouterState(
                route.params,
                route.queryParams,
                this.extractPathnameFromUrl(this.router.url),
            ),
        );
    }

    private extractPathnameFromUrl(url: string): string {
        return url.split("?")[0];
    }

    public numberParam$(key: string): Observable<number | undefined> {
        return this.params$.pipe(
            map((params) => this.getNumberParamByKey(params, key)),
            distinctUntilChanged(),
        );
    }

    private getNumberParamByKey(
        params: Params,
        key: string,
    ): number | undefined {
        const parsedValue = Number.parseInt(params[key], 10);
        return parsedValue || parsedValue === 0 ? parsedValue : undefined;
    }

    public numberParam(key: string): number | undefined {
        return this.getNumberParamByKey(this.params$.value, key);
    }

    public stringQueryParam(key: string): string | undefined {
        const value = this.queryParams$.value[key];
        return typeof value === "string" ? value : undefined;
    }

    private async navigate(
        url: string,
        extras?: NavigationExtras,
    ): Promise<void> {
        try {
            const pathname = this.extractPathnameFromUrl(url);
            const urlTree = this.router.parseUrl(url);
            await this.router.navigate([pathname], {
                queryParams: urlTree.queryParams,
                ...extras,
            });
        } catch {
            Actions.dispatch(
                new NotificationActions.Error("Не удалось перейти на страницу"),
            );
        }
    }

    public navigateToUrl(url: string): void {
        this.navigate(url, {
            queryParamsHandling: "merge",
        });
    }

    public setQueryParams(queryParams: Params): void {
        setTimeout(() => {
            // setTimeout is required to prevent queryParams missing when method called synchronously multiple times
            const pathname = this.extractPathnameFromUrl(this.router.url);
            this.navigate(pathname, {
                queryParams: queryParams,
                queryParamsHandling: "merge",
                replaceUrl: true,
            });
        });
    }

    public getQueryParams(): Params {
        return objectDeepClone(this.queryParams$.value);
    }

    public resetQueryParam(key: string): void {
        this.setQueryParams({
            [key]: undefined,
        });
    }
}
