import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { GoogleAnalyticsActions } from "@app/_infrastructure/action-handlers/google-analytics.action-handlers";
import { MetaActions } from "@app/_infrastructure/action-handlers/meta.action-handlers";
import { TitleActions } from "@app/_infrastructure/action-handlers/title.action-handlers";
import { Actions } from "ngx-action";

@Component({
    selector: "app-info",
    templateUrl: "./info.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class InfoComponent implements OnDestroy {
    constructor() {
        Actions.dispatch(new TitleActions.Set("Информация"));
        Actions.dispatch(
            new MetaActions.SetDescription("Контакты и прочая информация"),
        );
        Actions.dispatch(new GoogleAnalyticsActions.PageView());
    }

    public ngOnDestroy(): void {
        Actions.dispatch(new TitleActions.Reset());
        Actions.dispatch(new MetaActions.ResetDescription());
    }
}
