<div role="navigation">
    <ng-content />

    @for (listItem of viewModel; track listItem.id) {
        <app-category-list-item class="app-sidenav-item" [listItem]="listItem">
            @if (listItem.id) {
                <app-category-list-actions
                    class="app-sidenav-item-actions"
                    *appHasPermissions="[
                        PermissionsEnum.CategoryUpdate,
                        PermissionsEnum.CategoryDelete,
                    ]"
                    [listItem]="listItem"
                />
            }
        </app-category-list-item>
    }
</div>

<div>
    @if (loading$ | async) {
        <app-loading-state />
    }

    @if (error$ | async) {
        <app-error-state (refreshEvent)="refresh()" />
    }
</div>
