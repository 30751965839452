import { ChangeDetectionStrategy, Component } from "@angular/core";
import { SidenavComponent } from "@app/shell/sidenav/sidenav.component";
import { ToolbarComponent } from "@app/shell/toolbar/toolbar.component";

@Component({
    selector: "app-shell",
    templateUrl: "./shell.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ToolbarComponent, SidenavComponent],
})
export class ShellComponent {}
