<app-button-loading-indicator [loading]="loading" [rounded]="true">
    <button
        class="app-base-icon-button"
        type="button"
        mat-icon-button
        [matTooltip]="tooltip"
        [attr.aria-label]="tooltip"
        [disabled]="disabled || loading"
        (click)="onClick($event)"
    >
        <i class="app-icon-size-5" [ngClass]="icon"></i>
    </button>
</app-button-loading-indicator>
