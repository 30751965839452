import { NgClass } from "@angular/common";
import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterLink } from "@angular/router";
import { BaseButtonComponent } from "@app/_infrastructure/base-classes/base-button.component";
import { RouterLinkDirective } from "@app/_infrastructure/directives/router-link.directive";

@Component({
    selector: "app-icon-button-as-link",
    templateUrl: "./icon-button-as-link.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatButtonModule,
        MatTooltipModule,
        RouterLink,
        RouterLinkDirective,
        NgClass,
    ],
})
export class IconButtonAsLinkComponent extends BaseButtonComponent {
    @HostBinding("class") private readonly hostClass = "app-button-wrapper";

    @Input() public href = "";
    @Input() public icon = "";
    @Input() public tooltip = "";
}
