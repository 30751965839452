import {
    HttpEvent,
    HttpEventType,
    HttpHandlerFn,
    HttpRequest,
} from "@angular/common/http";
import { HttpMethodsEnum } from "@app/_api/_enums/http-methods.enum";
import { HTTP_CONTEXT_CACHE } from "@app/_api/http-context-tokens/http-context-cache";
import { Observable, of, tap } from "rxjs";

const storage = new Map<string, HttpEvent<unknown>>();

export function cacheInterceptor(
    request: HttpRequest<unknown>,
    next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
    const cache = request.context.get(HTTP_CONTEXT_CACHE);
    const cacheable = cache && request.method === HttpMethodsEnum.GET;
    const cacheKey = request.url;
    const cacheValue = storage.get(cacheKey);

    if (cacheable && cacheValue) {
        return of(cacheValue);
    }

    return next(request).pipe(
        tap((event) => {
            if (cacheable && event.type === HttpEventType.Response) {
                storage.set(cacheKey, event);
            }
        }),
    );
}
