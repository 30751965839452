import { Injectable } from "@angular/core";
import { TabSyncTypesEnum } from "@app/_infrastructure/_enums/tab-sync-types.enum";
import { IEventBus } from "@app/_infrastructure/interfaces/i-event-bus";
import { TabSyncPayload } from "@app/_tab-sync/_models/tab-sync-payload";
import { BroadcastChannelEventBus } from "@app/_tab-sync/broadcast-channel.event-bus";
import { LocalStorageEventBus } from "@app/_tab-sync/local-storage.event-bus";
import { Observable, Subject } from "rxjs";
import { filter } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class TabSyncService {
    private readonly name = "TabSyncService";
    private eventBus!: IEventBus<TabSyncPayload>;
    private readonly message$ = new Subject<TabSyncPayload>();

    constructor() {
        this.initEventBus();
        this.subscribeOnEvent();
    }

    private initEventBus(): void {
        this.eventBus = window.BroadcastChannel
            ? new BroadcastChannelEventBus(this.name)
            : new LocalStorageEventBus(this.name);
    }

    private subscribeOnEvent(): void {
        this.eventBus.event$.subscribe((event) => this.onEvent(event));
    }

    private onEvent(event: TabSyncPayload): void {
        this.message$.next(event);
    }

    public postMessage(message: TabSyncPayload): void {
        this.eventBus.send(message);
    }

    public onMessage<T extends TabSyncPayload>(
        type: TabSyncTypesEnum,
    ): Observable<T> {
        return (this.message$ as unknown as Observable<T>).pipe(
            filter((message: T) => {
                return message.type === type;
            }),
        );
    }
}
