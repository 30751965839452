import {
    ChangeDetectorRef,
    Directive,
    EmbeddedViewRef,
    inject,
    Input,
    OnDestroy,
    TemplateRef,
    ViewContainerRef,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { PermissionsEnum } from "@app/_auth/_enums/permissions.enum";
import { AuthService } from "@app/_auth/services/auth.service";
import { merge, ReplaySubject } from "rxjs";
import { map } from "rxjs/operators";

@Directive({
    selector: "[appHasPermissions]",
    standalone: true,
})
export class HasPermissionsDirective implements OnDestroy {
    private readonly viewContainerRef = inject(ViewContainerRef);
    private readonly templateRef = inject(TemplateRef<unknown>);
    private readonly authService = inject(AuthService);
    private readonly changeDetectorRef = inject(ChangeDetectorRef);

    private permissions?: PermissionsEnum[];
    private readonly permissionsChange$ = new ReplaySubject<void>(1);
    private embeddedViewRef?: EmbeddedViewRef<unknown>;

    @Input({ required: true })
    public set appHasPermissions(permissions: PermissionsEnum[] | undefined) {
        this.permissions = permissions;
        this.permissionsChange$.next();
    }

    constructor() {
        this.subscribeOnPermissionsChange();
    }

    private subscribeOnPermissionsChange(): void {
        merge(this.permissionsChange$, this.authService.permissionsChange$)
            .pipe(
                map(() =>
                    this.authService.hasSomeOfPermissions(this.permissions),
                ),
                takeUntilDestroyed(),
            )
            .subscribe((hasPermissions) => this.updateView(hasPermissions));
    }

    private updateView(hasPermissions: boolean): void {
        if (hasPermissions) {
            this.createView();
        } else {
            this.destroyView();
        }
        this.changeDetectorRef.markForCheck();
    }

    private createView(): void {
        if (!this.embeddedViewRef) {
            this.embeddedViewRef = this.viewContainerRef.createEmbeddedView(
                this.templateRef,
            );
        }
    }

    private destroyView(): void {
        if (this.embeddedViewRef) {
            this.viewContainerRef.clear();
            this.embeddedViewRef = undefined;
        }
    }

    public ngOnDestroy(): void {
        this.destroyView();
    }
}
