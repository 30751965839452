import { Routes } from "@angular/router";
import { AUTH_WEB_PATHS } from "@app/auth/auth.routes-factories";

export const authRoutes: Routes = [
    {
        path: AUTH_WEB_PATHS.root,
        children: [
            {
                path: AUTH_WEB_PATHS.signIn,
                loadComponent: () =>
                    import("@app/auth/sign-in/sign-in.component"),
            },
        ],
    },
];
