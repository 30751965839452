import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { ApiResponse } from "@app/_api/_models/api.response";
import { Empty } from "@app/_infrastructure/_models/empty";
import { AuthResponse } from "@app/auth/_models/auth.response";
import { AUTH_API_ROUTES } from "@app/auth/auth.routes-factories";
import { SignInRequest } from "@app/auth/sign-in/_models/sign-in.request";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class AuthApiService {
    private readonly httpClient = inject(HttpClient);

    public signIn(
        request: SignInRequest,
    ): Observable<ApiResponse<AuthResponse>> {
        return this.httpClient.post<ApiResponse<AuthResponse>>(
            AUTH_API_ROUTES.signIn(),
            request,
        );
    }

    public signOut(): Observable<Empty> {
        return this.httpClient.post<Empty>(AUTH_API_ROUTES.signOut(), {});
    }

    public userDetails(): Observable<ApiResponse<AuthResponse | undefined>> {
        return this.httpClient.get<ApiResponse<AuthResponse | undefined>>(
            AUTH_API_ROUTES.userDetails(),
        );
    }
}
