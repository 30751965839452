<div class="relative">
    <ng-content />
    @if (loading) {
        <div
            class="absolute top-0 w-full h-full flex items-center justify-center z-10 bg-white opacity-50"
            [class.rounded-full]="rounded"
        >
            <mat-spinner [strokeWidth]="2" [diameter]="25" />
            <app-progress class="ml-1 app-text-primary" />
        </div>
    }
</div>
