<a
    class="app-base-icon-button"
    [routerLink]="href"
    mat-icon-button
    [matTooltip]="tooltip"
    [attr.aria-label]="tooltip"
    (click)="onClick($event)"
>
    <i class="app-icon-size-5" [ngClass]="icon"></i>
</a>
