import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { BaseButtonComponent } from "@app/_infrastructure/base-classes/base-button.component";
import { ButtonLoadingIndicatorComponent } from "@app/_infrastructure/components/button-loading-indicator/button-loading-indicator.component";

@Component({
    selector: "app-text-button",
    templateUrl: "./text-button.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatButtonModule, ButtonLoadingIndicatorComponent],
})
export class TextButtonComponent extends BaseButtonComponent {
    @HostBinding("class") private readonly hostClass = "app-button-wrapper";

    @Input() public text = "";
}
