import { inject, Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { DOMAIN_NAME } from "@app/_infrastructure/_constants/domain-name.const";
import {
    ActionHandler,
    initActionHandlers,
    WithActionHandlers,
} from "ngx-action";

export namespace TitleActions {
    export class Set {
        constructor(public text: string) {}
    }

    export class Reset {}
}

@WithActionHandlers()
@Injectable({ providedIn: "root" })
export class TitleActionHandlers {
    private readonly title = inject(Title);

    constructor() {
        initActionHandlers(this);
    }

    @ActionHandler(TitleActions.Set)
    private titleSetActionHandler(action: TitleActions.Set): void {
        this.title.setTitle(action.text);
    }

    @ActionHandler(TitleActions.Reset)
    private titleResetActionHandler(): void {
        this.title.setTitle(DOMAIN_NAME);
    }
}
