import {
    ChangeDetectionStrategy,
    Component,
    inject,
    OnInit,
} from "@angular/core";
import { ApiResponse } from "@app/_api/_models/api.response";
import { PermissionsEnum } from "@app/_auth/_enums/permissions.enum";
import { BaseDetailsComponent } from "@app/_infrastructure/base-classes/base-details.component";
import { AppRouterService } from "@app/_infrastructure/services/app-router.service";
import { sharedImports } from "@app/_infrastructure/shared-imports";
import { CategoryListItemResponse } from "@app/category/_models/category-list-item.response";
import { CategoryListItemViewModel } from "@app/category/_models/category-list-item.view-model";
import { categoryMapper } from "@app/category/_models/category.mapper";
import { CategoryActions } from "@app/category/category.actions";
import { CategoryApiService } from "@app/category/category.api-service";
import { CATEGORY_WEB_PATHS } from "@app/category/category.routes-factories";
import { CategoryStore } from "@app/category/category.store";
import { CategoryListActionsComponent } from "@app/category/list/actions/category-list-actions.component";
import { CategoryListItemComponent } from "@app/category/list/item/category-list-item.component";
import { PlantActions } from "@app/plant/plant.actions";
import { PLANT_WEB_ROUTES } from "@app/plant/plant.routes-factories";
import {
    ActionHandler,
    Actions,
    initActionHandlers,
    WithActionHandlers,
} from "ngx-action";
import { Observable } from "rxjs";

@WithActionHandlers()
@Component({
    selector: "app-category-list",
    templateUrl: "./category-list.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        sharedImports,
        CategoryListItemComponent,
        CategoryListActionsComponent,
    ],
})
export class CategoryListComponent
    extends BaseDetailsComponent<CategoryListItemViewModel[]>
    implements OnInit
{
    private readonly apiService = inject(CategoryApiService);
    private readonly appRouterService = inject(AppRouterService);
    private readonly categoryStore = inject(CategoryStore);

    protected readonly PermissionsEnum = PermissionsEnum;

    constructor() {
        super();
        initActionHandlers(this);
    }

    public override ngOnInit(): void {
        super.ngOnInit();
        this.refresh();
    }

    protected override refreshTriggers(): Observable<unknown> {
        return Actions.onAction(
            CategoryActions.CreateSuccess,
            CategoryActions.EditSuccess,
            CategoryActions.DeleteSuccess,
            PlantActions.CreateSuccess,
            PlantActions.EditSuccess,
            PlantActions.DeleteSuccess,
        );
    }

    protected refreshObservable(): Observable<
        ApiResponse<CategoryListItemResponse[]>
    > {
        return this.apiService.list();
    }

    protected mapResponseToViewModel(
        response: ApiResponse<CategoryListItemResponse[]>,
    ): CategoryListItemViewModel[] {
        return categoryMapper.mapListResponseToViewModel(response.data);
    }

    protected override onRefreshSuccess(): void {
        this.categoryStore.listItems$.next(this.viewModel!);
    }

    @ActionHandler(CategoryActions.DeleteSuccess)
    private categoryDeleteSuccessActionHandler(
        action: CategoryActions.DeleteSuccess,
    ): void {
        const currentId = this.appRouterService.numberParam(
            CATEGORY_WEB_PATHS.id,
        );
        if (currentId === action.id) {
            this.appRouterService.navigateToUrl(PLANT_WEB_ROUTES.list());
        }
    }
}
