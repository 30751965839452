import { Injectable } from "@angular/core";
import { CategoryListItemViewModel } from "@app/category/_models/category-list-item.view-model";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: "root" })
export class CategoryStore {
    public readonly listItems$ = new BehaviorSubject<
        CategoryListItemViewModel[]
    >([]);
}
