import { Injectable } from "@angular/core";

@Injectable()
export class ProgressIdService {
    private id?: symbol;

    public setId(id: symbol): void {
        this.id = id;
    }

    public getId(): symbol {
        if (this.id) {
            return this.id;
        } else {
            throw new Error("progress id not set");
        }
    }
}
