import { numberPipeTransform } from "@app/_infrastructure/pipes/number.pipe";
import { CategoryFormViewModel } from "@app/category/_models/category-form.view-model";
import { CategoryListItemResponse } from "@app/category/_models/category-list-item.response";
import { CategoryListItemViewModel } from "@app/category/_models/category-list-item.view-model";
import { CategoryRequest } from "@app/category/_models/category.request";
import { CategoryResponse } from "@app/category/_models/category.response";
import { CATEGORY_WEB_ROUTES } from "@app/category/category.routes-factories";
import { PLANT_WEB_ROUTES } from "@app/plant/plant.routes-factories";

class CategoryMapper {
    public createEmptyFormViewModel(): CategoryFormViewModel {
        return new CategoryFormViewModel(undefined);
    }

    public mapResponseToFormViewModel(
        response: CategoryResponse,
    ): CategoryFormViewModel {
        return new CategoryFormViewModel(response.name);
    }

    public mapFormViewModelToRequest(
        viewModel: CategoryFormViewModel,
    ): CategoryRequest {
        return new CategoryRequest(viewModel.name!);
    }

    public mapListResponseToViewModel(
        response: CategoryListItemResponse[],
    ): CategoryListItemViewModel[] {
        return response.map((item) => {
            return new CategoryListItemViewModel(
                item.id,
                item.name,
                numberPipeTransform(item.plantsCount),
                item.id ? CATEGORY_WEB_ROUTES.edit(item.id) : undefined,
                PLANT_WEB_ROUTES.list(item.id),
            );
        });
    }
}

export const categoryMapper = new CategoryMapper();
