import { inject } from "@angular/core";
import { CanDeactivateFn } from "@angular/router";
import { ICanDeactivate } from "@app/_infrastructure/interfaces/i-can-deactivate";
import { DialogService } from "@app/_infrastructure/services/dialog.service";
import { Observable } from "rxjs";

export const canDeactivateGuard: () => CanDeactivateFn<ICanDeactivate> = () => {
    return function (component: ICanDeactivate): Observable<boolean> | boolean {
        const dialogService = inject(DialogService);
        return component.canDeactivate()
            ? true
            : dialogService.confirm(
                  "Остались несохранённые изменения.<br/> Всё равно продолжить?",
              );
    };
};
