export enum LayoutBreakpointsEnum {
    // applied when greater or equal (>=)

    Sm = 640,

    Md = 768,

    Lg = 1024,

    Xl = 1280,

    Xxl = 1536,
}
