import { AsyncPipe, NgIf, PercentPipe } from "@angular/common";
import {
    ChangeDetectionStrategy,
    Component,
    inject,
    OnInit,
} from "@angular/core";
import { ApiProgressService } from "@app/_api/services/api-progress.service";
import { ProgressIdService } from "@app/_progress/progress-id.service";
import { EMPTY, Observable } from "rxjs";

@Component({
    selector: "app-progress",
    templateUrl: "./progress.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AsyncPipe, PercentPipe, NgIf],
})
export class ProgressComponent implements OnInit {
    private readonly progressIdService = inject(ProgressIdService, {
        optional: true,
    });
    private readonly apiProgressService = inject(ApiProgressService);

    protected progress$: Observable<number> = EMPTY;

    public ngOnInit(): void {
        if (this.progressIdService) {
            this.progress$ = this.apiProgressService.getProgress(
                this.progressIdService.getId(),
            );
        }
    }
}
