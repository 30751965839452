import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    ElementRef,
    inject,
    OnInit,
    ViewChild,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { PermissionsEnum } from "@app/_auth/_enums/permissions.enum";
import { AppRouterService } from "@app/_infrastructure/services/app-router.service";
import { sharedImports } from "@app/_infrastructure/shared-imports";
import { ADMIN_PANEL_WEB_ROUTES } from "@app/admin-panel/admin-panel.routes-factories";
import { CACHE_WEB_ROUTES } from "@app/cache/cache.routes-factories";
import { CATEGORY_WEB_ROUTES } from "@app/category/category.routes-factories";
import { CategoryListComponent } from "@app/category/list/category-list.component";
import { INFO_WEB_ROUTES } from "@app/info/info.routes-factories";
import { PLANT_WEB_ROUTES } from "@app/plant/plant.routes-factories";
import { ShellService } from "@app/shell/shell.service";
import { map } from "rxjs/operators";

@Component({
    selector: "app-sidenav",
    templateUrl: "./sidenav.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [sharedImports, CategoryListComponent],
})
export class SidenavComponent implements OnInit {
    private readonly destroyRef = inject(DestroyRef);
    private readonly appRouterService = inject(AppRouterService);
    private readonly shellService = inject(ShellService);

    @ViewChild("sidenavContent", { static: true, read: ElementRef })
    private readonly sidenavContent!: ElementRef;

    protected readonly PermissionsEnum = PermissionsEnum;

    protected readonly infoUrl = INFO_WEB_ROUTES.root();
    protected readonly adminPanelUrl = ADMIN_PANEL_WEB_ROUTES.root();
    protected readonly cacheUrl = CACHE_WEB_ROUTES.list();
    protected readonly categoryCreateUrl = CATEGORY_WEB_ROUTES.create();
    protected readonly plantCreateUrl = PLANT_WEB_ROUTES.create();

    protected mobileLayout$ = this.shellService.lessThanMd$;
    protected opened$ = this.shellService.sidenavOpened$;
    protected mode$ = this.mobileLayout$.pipe(
        map((value) => (value ? "over" : "side")),
    );

    public ngOnInit(): void {
        this.subscribeOnRouteChange();
    }

    private subscribeOnRouteChange(): void {
        this.appRouterService.pathname$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.scrollToTop());
    }

    private scrollToTop(): void {
        if (this.sidenavContent) {
            this.sidenavContent.nativeElement.scrollTop = 0;
        }
    }

    protected onOpenedChange(opened: boolean): void {
        this.shellService.setSidenavOpened(opened);
    }
}
