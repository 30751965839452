import {
    HttpEvent,
    HttpEventType,
    HttpHandlerFn,
    HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export function convertNullUndefinedInterceptor(
    request: HttpRequest<unknown>,
    next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
    convertNullUndefined(request.body, undefined, null);
    return next(request).pipe(
        map((response: HttpEvent<unknown>) => {
            if (response.type === HttpEventType.Response) {
                // eslint-disable-next-line unicorn/no-useless-undefined
                convertNullUndefined(response.body, null, undefined);
            }
            return response;
        }),
    );
}

function convertNullUndefined<
    From extends null | undefined,
    To extends From extends null ? undefined : null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
>(object: any | null, from: From, to: To): void {
    if (object) {
        Object.entries(object).forEach(([key, value]) => {
            if (value === from) {
                object[key] = to;
            } else if (typeof value === "object") {
                convertNullUndefined(value, from, to);
            }
        });
    }
}
