import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { ApiResponse } from "@app/_api/_models/api.response";
import { IdName } from "@app/_api/_models/id-name";
import { Empty } from "@app/_infrastructure/_models/empty";
import { CategoryListItemResponse } from "@app/category/_models/category-list-item.response";
import { CategoryRequest } from "@app/category/_models/category.request";
import { CategoryResponse } from "@app/category/_models/category.response";
import { CATEGORY_API_ROUTES } from "@app/category/category.routes-factories";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class CategoryApiService {
    private readonly httpClient = inject(HttpClient);

    public create(request: CategoryRequest): Observable<ApiResponse<number>> {
        return this.httpClient.post<ApiResponse<number>>(
            CATEGORY_API_ROUTES.create(),
            request,
        );
    }

    public list(): Observable<ApiResponse<CategoryListItemResponse[]>> {
        return this.httpClient.get<ApiResponse<CategoryListItemResponse[]>>(
            CATEGORY_API_ROUTES.list(),
        );
    }

    public summary(): Observable<ApiResponse<IdName<number>[]>> {
        return this.httpClient.get<ApiResponse<IdName<number>[]>>(
            CATEGORY_API_ROUTES.summary(),
        );
    }

    public details(id: number): Observable<ApiResponse<CategoryResponse>> {
        return this.httpClient.get<ApiResponse<CategoryResponse>>(
            CATEGORY_API_ROUTES.details(id),
        );
    }

    public edit(request: CategoryRequest, id: number): Observable<Empty> {
        return this.httpClient.put<Empty>(
            CATEGORY_API_ROUTES.edit(id),
            request,
        );
    }

    public delete(id: number): Observable<Empty> {
        return this.httpClient.delete<Empty>(CATEGORY_API_ROUTES.delete(id));
    }
}
