import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    inject,
    Input,
} from "@angular/core";
import { AuthService } from "@app/_auth/services/auth.service";
import { DOMAIN_NAME } from "@app/_infrastructure/_constants/domain-name.const";
import { sharedImports } from "@app/_infrastructure/shared-imports";
import { AuthSignOutSender } from "@app/auth/_cqs/auth-sign-out.sender";
import { AUTH_WEB_ROUTES } from "@app/auth/auth.routes-factories";
import { ShellService } from "@app/shell/shell.service";

@Component({
    selector: "[app-toolbar]",
    templateUrl: "./toolbar.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [sharedImports],
})
export class ToolbarComponent {
    private readonly authService = inject(AuthService);
    private readonly shellService = inject(ShellService);
    protected readonly authSignOutSender = inject(AuthSignOutSender);

    @HostBinding("class") private readonly hostClass =
        "flex items-center px-4 h-12 app-bg-primary text-white";

    @Input({ alias: "app-toolbar", required: true })
    public hostInput: undefined;

    protected readonly loggedIn$ = this.authService.loggedIn$;
    protected readonly mobileLayout$ = this.shellService.lessThanMd$;
    protected readonly showTitle$ = this.shellService.md$;
    protected readonly appTitle = DOMAIN_NAME;
    protected readonly signInUrl = AUTH_WEB_ROUTES.signIn();

    protected toggleSidebar(): void {
        this.shellService.toggleSidenav();
    }
}
