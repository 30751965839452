<a
    class="app-sidenav-item-link"
    [routerLink]="listItem.plantListUrl"
    routerLinkActive="app-sidenav-item-link-active"
>
    <span class="flex-auto">
        {{ listItem.name }}
    </span>
    <span class="flex-none app-text-primary"
        >&nbsp;({{ listItem.plantsCount }})</span
    >
</a>

<ng-content /><!-- Actions -->
