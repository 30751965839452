import { Injectable } from "@angular/core";
import { PermissionsEnum } from "@app/_auth/_enums/permissions.enum";
import { AuthResponse } from "@app/auth/_models/auth.response";
import { ReplaySubject, Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class AuthService {
    private permissions: PermissionsEnum[] = [];
    private readonly loggedInSubject$ = new ReplaySubject<boolean>(1);
    private readonly permissionsChangeSubject$ = new Subject<void>();

    public readonly loggedIn$ = this.loggedInSubject$.asObservable();
    public readonly permissionsChange$ =
        this.permissionsChangeSubject$.asObservable();

    public signIn(data: AuthResponse): void {
        this.permissions = data.permissions;
        this.loggedInSubject$.next(true);
        this.permissionsChangeSubject$.next();
    }

    public signOut(): void {
        this.permissions = [];
        this.loggedInSubject$.next(false);
        this.permissionsChangeSubject$.next();
    }

    public hasSomeOfPermissions(
        checkPermissions: PermissionsEnum[] = [],
    ): boolean {
        return checkPermissions.some((checkPermission) =>
            this.permissions.includes(checkPermission),
        );
    }
}
