import { Routes } from "@angular/router";
import { PermissionsEnum } from "@app/_auth/_enums/permissions.enum";
import { permissionsGuard } from "@app/_auth/guards/permissions.guard";
import { canDeactivateGuard } from "@app/_infrastructure/guards/can-deactivate.guard";
import { APP_WEB_PATHS } from "@app/app.routes-factories";
import { CATEGORY_WEB_PATHS } from "@app/category/category.routes-factories";
import { PLANT_WEB_PATHS } from "@app/plant/plant.routes-factories";

export const plantRoutes: Routes = [
    {
        path: `${PLANT_WEB_PATHS.root}/${APP_WEB_PATHS.create}`,
        loadComponent: () => import("@app/plant/create/plant-create.component"),
        canActivate: [permissionsGuard([PermissionsEnum.PlantCreate])],
        canDeactivate: [canDeactivateGuard()],
    },
    {
        path: `${CATEGORY_WEB_PATHS.root}/:${CATEGORY_WEB_PATHS.id}/${PLANT_WEB_PATHS.root}`,
        children: [
            {
                path: "",
                pathMatch: "full",
                loadComponent: () =>
                    import("@app/plant/list/plant-list.component"),
            },
            {
                path: `:${PLANT_WEB_PATHS.id}`,
                loadComponent: () =>
                    import(
                        "@app/plant/details/page/plant-details-page.component"
                    ),
            },
            {
                path: `:${PLANT_WEB_PATHS.id}/${APP_WEB_PATHS.edit}`,
                loadComponent: () =>
                    import("@app/plant/edit/plant-edit.component"),
                canActivate: [permissionsGuard([PermissionsEnum.PlantUpdate])],
                canDeactivate: [canDeactivateGuard()],
            },
        ],
    },
];
