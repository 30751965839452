import { ErrorHandler, inject, Injectable, NgZone } from "@angular/core";
import { StorageKeysEnum } from "@app/_infrastructure/_enums/storage-keys.enum";
import { NotificationActions } from "@app/_infrastructure/action-handlers/notification.action-handlers";
import { LocalStorageLoggerService } from "@app/_infrastructure/services/local-storage-logger.service";
import { Actions } from "ngx-action";

@Injectable()
export class GlobalErrorHandlerService extends ErrorHandler {
    private readonly localStorageLoggerService = inject(
        LocalStorageLoggerService,
    );
    private readonly ngZone = inject(NgZone);

    private readonly lastRefreshDelta = 15_000;

    public override handleError(error: unknown): void {
        if (this.handleChunkLoadError(error)) {
            return;
        }

        this.ngZone.run(() => {
            const stack = (error as Error | void)?.stack;
            this.localStorageLoggerService.log(`${error}\n${stack}`);
            Actions.dispatch(
                new NotificationActions.Error("Необработанная ошибка."),
            );
        });
        super.handleError(error);
    }

    private handleChunkLoadError(error: unknown): boolean {
        const chunkLoadError = !!error?.toString().includes("ChunkLoadError");
        if (chunkLoadError) {
            const lastRefreshTime =
                Number(
                    sessionStorage.getItem(
                        StorageKeysEnum.ChunkErrorLastRefresh,
                    ),
                ) || 0;
            const currentTime = Date.now();
            const lastRefreshDelta = Math.abs(currentTime - lastRefreshTime);
            if (lastRefreshDelta > this.lastRefreshDelta) {
                window.location.reload();
                sessionStorage.setItem(
                    StorageKeysEnum.ChunkErrorLastRefresh,
                    currentTime.toString(),
                );
                return true;
            }
        }
        return false;
    }
}
