import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Input,
} from "@angular/core";
import { PermissionsEnum } from "@app/_auth/_enums/permissions.enum";
import { ButtonLoadingIndicatorComponent } from "@app/_infrastructure/components/button-loading-indicator/button-loading-indicator.component";
import { sharedImports } from "@app/_infrastructure/shared-imports";
import { CategoryDeleteSender } from "@app/category/_cqs/category-delete.sender";
import { CategoryDeletePayload } from "@app/category/_models/category-delete.payload";
import { CategoryListItemViewModel } from "@app/category/_models/category-list-item.view-model";

@Component({
    selector: "app-category-list-actions",
    templateUrl: "./category-list-actions.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [sharedImports, ButtonLoadingIndicatorComponent],
})
export class CategoryListActionsComponent {
    @Input({ required: true }) public listItem!: CategoryListItemViewModel;

    protected readonly categoryDeleteSender = inject(CategoryDeleteSender);

    protected readonly PermissionsEnum = PermissionsEnum;

    protected delete(listItem: CategoryListItemViewModel): void {
        const payload = new CategoryDeletePayload(listItem.id!, listItem.name!);
        this.categoryDeleteSender.dispatch(payload);
    }
}
