import {
    ChangeDetectorRef,
    DestroyRef,
    Directive,
    inject,
    OnInit,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ApiErrorsSummary } from "@app/_api/_models/api-errors.summary";
import { DetailsStatesEnum } from "@app/_infrastructure/_enums/details-states.enum";
import { ApiErrorActions } from "@app/_infrastructure/action-handlers/api-error.action-handlers";
import { skipErrors } from "@app/_infrastructure/rxjs";
import { Actions } from "ngx-action";
import {
    BehaviorSubject,
    EMPTY,
    merge,
    Observable,
    Subject,
    throwError,
} from "rxjs";
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    map,
    switchMap,
    tap,
} from "rxjs/operators";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseDetailsComponent<ViewModel, Response = unknown>
    implements OnInit
{
    protected readonly changeDetectorRef = inject(ChangeDetectorRef);
    protected readonly destroyRef = inject(DestroyRef);

    private readonly refresh$ = new Subject<void>();
    private readonly detailsState$ = new BehaviorSubject<DetailsStatesEnum>(
        DetailsStatesEnum.Loading,
    );

    protected viewModel?: ViewModel;

    protected readonly loading$ = this.detailsState$.pipe(
        map((state) => state === DetailsStatesEnum.Loading),
        distinctUntilChanged(),
    );
    protected readonly error$ = this.detailsState$.pipe(
        map((state) => state === DetailsStatesEnum.Error),
        distinctUntilChanged(),
    );

    public ngOnInit(): void {
        this.subscribeOnRefresh();
    }

    protected reset(): void {
        this.viewModel = undefined;
        this.setDetailsState(DetailsStatesEnum.Loading);
        this.changeDetectorRef.markForCheck();
    }

    protected setDetailsState(state: DetailsStatesEnum): void {
        this.detailsState$.next(state);
    }

    protected refresh(): void {
        this.refresh$.next();
    }

    private subscribeOnRefresh(): void {
        merge(this.refreshTriggers(), this.refresh$)
            .pipe(
                debounceTime(1), // handle multiple source observables emit at once
                tap(() => this.reset()),
                switchMap(() =>
                    this.refreshObservable().pipe(
                        catchError((apiErrorsSummary: ApiErrorsSummary) => {
                            this._onRefreshError(apiErrorsSummary);
                            return throwError(() => undefined);
                        }),
                        skipErrors(),
                    ),
                ),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe((response) => this._onRefreshSuccess(response));
    }

    protected refreshTriggers(): Observable<unknown> {
        return EMPTY;
    }

    protected abstract refreshObservable(): Observable<Response>;

    protected abstract mapResponseToViewModel(response: Response): ViewModel;

    private _onRefreshSuccess(response: Response): void {
        this.viewModel = this.mapResponseToViewModel(response);
        this.setDetailsState(DetailsStatesEnum.Loaded);
        this.onRefreshSuccess(response);
        this.changeDetectorRef.markForCheck();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected onRefreshSuccess(response: Response): void {}

    private _onRefreshError(apiErrorsSummary: ApiErrorsSummary): void {
        this.setDetailsState(DetailsStatesEnum.Error);
        this.onRefreshError(apiErrorsSummary);
        this.changeDetectorRef.markForCheck();
    }

    protected onRefreshError(apiErrorsSummary: ApiErrorsSummary): void {
        Actions.dispatch(new ApiErrorActions.Handle(apiErrorsSummary));
    }
}
