import {
    HttpEvent,
    HttpEventType,
    HttpHandlerFn,
    HttpRequest,
} from "@angular/common/http";
import { inject } from "@angular/core";
import { HTTP_CONTEXT_PROGRESS_ID } from "@app/_api/http-context-tokens/http-context-progress-id";
import { ApiProgressService } from "@app/_api/services/api-progress.service";
import { getHttpProgress } from "@app/_api/utils/get-http-progress";
import { Observable } from "rxjs";
import { finalize, tap } from "rxjs/operators";

export function apiProgressInterceptor(
    request: HttpRequest<unknown>,
    next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
    const apiProgressService = inject(ApiProgressService);
    const progressId = request.context.get(HTTP_CONTEXT_PROGRESS_ID);

    return next(request).pipe(
        tap((response) => {
            if (progressId && response.type === HttpEventType.UploadProgress) {
                apiProgressService.setProgress(
                    progressId,
                    getHttpProgress(response),
                );
            }
        }),
        finalize(() => {
            if (progressId) {
                apiProgressService.resetProgress(progressId);
            }
        }),
    );
}
