import { Routes } from "@angular/router";
import { adminPanelRoutes } from "@app/admin-panel/admin-panel.routes";
import { APP_WEB_PATHS } from "@app/app.routes-factories";
import { authRoutes } from "@app/auth/auth.routes";
import { cacheRoutes } from "@app/cache/cache.routes";
import { categoryRoutes } from "@app/category/category.routes";
import { infoRoutes } from "@app/info/info.routes";
import { plantRoutes } from "@app/plant/plant.routes";
import { ShellComponent } from "@app/shell/shell.component";

export const appRoutes: Routes = [
    ...authRoutes,
    {
        path: "",
        component: ShellComponent,
        children: [
            ...infoRoutes,
            ...adminPanelRoutes,
            ...cacheRoutes,
            ...categoryRoutes,
            ...plantRoutes,
            { path: "**", redirectTo: APP_WEB_PATHS.redirectTo },
        ],
    },
    { path: "**", redirectTo: APP_WEB_PATHS.redirectTo },
];
