<app-icon-button
    [matMenuTriggerFor]="sidenavMenu"
    icon="icon-ellipsis-vert"
    tooltip="Меню"
/>

<mat-menu #sidenavMenu="matMenu">
    <a
        *appHasPermissions="[PermissionsEnum.CategoryUpdate]"
        mat-menu-item
        [routerLink]="listItem.editUrl"
    >
        <span class="app-text-primary">
            <i class="app-icon-size-5 icon-pencil"></i>
            Редактировать
        </span>
    </a>

    <app-button-loading-indicator
        [loading]="
            (categoryDeleteSender.dispatchingSet$ | async)!.has(listItem.id!)
        "
    >
        <button
            *appHasPermissions="[PermissionsEnum.CategoryDelete]"
            type="button"
            mat-menu-item
            (click)="delete(listItem)"
        >
            <span class="app-text-error">
                <i class="app-icon-size-5 icon-trash-empty"></i>
                Удалить
            </span>
        </button>
    </app-button-loading-indicator>
</mat-menu>
