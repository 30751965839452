import { Directive, inject } from "@angular/core";
import { RouterLink } from "@angular/router";

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "[routerLink]",
    standalone: true,
})
export class RouterLinkDirective {
    private readonly routerLink = inject(RouterLink);

    constructor() {
        this.routerLink.queryParamsHandling =
            this.routerLink.queryParamsHandling ?? "merge";
    }
}
