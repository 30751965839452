import { IEventBus } from "@app/_infrastructure/interfaces/i-event-bus";
import { Subject } from "rxjs";

export class BroadcastChannelEventBus<T> implements IEventBus<T> {
    private readonly broadcastChannel: BroadcastChannel;

    public readonly event$ = new Subject<T>();

    constructor(name: string) {
        this.broadcastChannel = new BroadcastChannel(name);
        this.subscribeOnMessage();
    }

    private subscribeOnMessage(): void {
        this.broadcastChannel.addEventListener(
            "message",
            (event: MessageEvent<T>) => {
                this.onEvent(event);
            },
        );
    }

    private onEvent(event: MessageEvent<T>): void {
        this.event$.next(event.data);
    }

    public send(event: T): void {
        this.broadcastChannel.postMessage(event);
    }
}
