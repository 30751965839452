<button
    class="app-base-dialog-button"
    type="button"
    mat-button
    (click)="close()"
    (keydown.enter)="preventDialogReopenOnLongKeyPress($event)"
    (keyup.enter)="close()"
>
    {{ text }}
</button>
