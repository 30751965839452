import { Directive, EventEmitter, Input, Output } from "@angular/core";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseButtonComponent {
    @Output() public readonly btnClick = new EventEmitter<MouseEvent>();

    @Input() public disabled = false;
    @Input() public loading = false;

    protected onClick(event: MouseEvent): void {
        this.btnClick.emit(event);
    }
}
