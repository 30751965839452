import { inject, Injectable } from "@angular/core";
import { LayoutBreakpointsEnum } from "@app/_infrastructure/_enums/layout-breakpoints.enum";
import { OptimizedBehaviorSubject } from "@app/_infrastructure/rxjs";
import { AppRouterService } from "@app/_infrastructure/services/app-router.service";
import { fromEvent } from "rxjs";
import { debounceTime, map, startWith } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ShellService {
    private readonly appRouterService = inject(AppRouterService);

    private readonly resizeThrottleTime = 100;

    private readonly sidenavOpenedSubject$ =
        new OptimizedBehaviorSubject<boolean>(true);
    private readonly screenWidth$ = new OptimizedBehaviorSubject<number>(
        window.innerWidth,
    );

    public readonly lessThanMd$ = this.screenWidth$.pipe(
        map((value) => value < LayoutBreakpointsEnum.Md),
    );
    public readonly md$ = this.screenWidth$.pipe(
        map((value) => value >= LayoutBreakpointsEnum.Md),
    );
    public readonly lg$ = this.screenWidth$.pipe(
        map((value) => value >= LayoutBreakpointsEnum.Lg),
    );

    public readonly sidenavOpened$ = this.sidenavOpenedSubject$.asObservable();

    constructor() {
        this.subscribeOnWindowResize();
        this.subscribeOnNavigationEnd();
    }

    public toggleSidenav(): void {
        this.setSidenavOpened(!this.sidenavOpenedSubject$.value);
    }

    public setSidenavOpened(opened: boolean): void {
        this.sidenavOpenedSubject$.next(opened);
    }

    private subscribeOnWindowResize(): void {
        fromEvent(window, "resize")
            .pipe(startWith(""), debounceTime(this.resizeThrottleTime))
            .subscribe(() => this.onWindowResize());
    }

    private onWindowResize(): void {
        this.screenWidth$.next(window.innerWidth);
        this.forceUpdateSidenavOpened();
    }

    private subscribeOnNavigationEnd(): void {
        this.appRouterService.pathname$.subscribe(() =>
            this.forceUpdateSidenavOpened(),
        );
    }

    private forceUpdateSidenavOpened(): void {
        this.setSidenavOpened(this.md$.value);
    }
}
