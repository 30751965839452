import { PlantExportExtensionsType } from "@app/plant/_models/plant-export-extensions.type";

function beautifyCategoryId(categoryId?: number): number | string {
    return categoryId ?? "all";
}

export const PLANT_WEB_PATHS = {
    root: "plants",
    id: "plantId",
};

export const PLANT_WEB_ROUTES = {
    list: (categoryId?: number) =>
        `/categories/${beautifyCategoryId(categoryId)}/plants`,
    create: () => "/plants/create",
    details: (categoryId: number | undefined, id: number) =>
        `/categories/${beautifyCategoryId(categoryId)}/plants/${id}`,
    edit: (categoryId: number | undefined, id: number) =>
        `/categories/${beautifyCategoryId(categoryId)}/plants/${id}/edit`,
};

export const PLANT_API_ROUTES = {
    list: () => "/plants",
    create: () => "/plants",
    details: (id: number) => `/plants/${id}`,
    edit: (id: number) => `/plants/${id}`,
    delete: (id: number) => `/plants/${id}`,
    export: (extension: PlantExportExtensionsType) =>
        `/plants/export/${extension}`,
    statuses: () => `/plants/statuses`,
};
