export const CATEGORY_WEB_PATHS = {
    root: "categories",
    id: "categoryId",
};

export const CATEGORY_WEB_ROUTES = {
    create: () => "/categories/create",
    edit: (id: number) => `/categories/${id}/edit`,
};

export const CATEGORY_API_ROUTES = {
    list: () => "/categories",
    summary: () => "/categories/summary",
    create: () => "/categories",
    details: (id: number) => `/categories/${id}`,
    edit: (id: number) => `/categories/${id}`,
    delete: (id: number) => `/categories/${id}`,
};
