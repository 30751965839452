import { inject, Injectable } from "@angular/core";
import { AuthService } from "@app/_auth/services/auth.service";
import { Empty } from "@app/_infrastructure/_models/empty";
import { NotificationActions } from "@app/_infrastructure/action-handlers/notification.action-handlers";
import { BaseSender } from "@app/_infrastructure/base-classes/base.sender";
import { SignOutTabSyncPayload } from "@app/_tab-sync/_models/sign-out.tab-sync-payload";
import { TabSyncService } from "@app/_tab-sync/tab-sync.service";
import { AuthApiService } from "@app/auth/auth.api-service";
import { Actions } from "ngx-action";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class AuthSignOutSender extends BaseSender {
    private readonly apiService = inject(AuthApiService);
    private readonly authService = inject(AuthService);
    private readonly tabSyncService = inject(TabSyncService);

    protected dispatchObservable(): Observable<Empty> {
        return this.apiService.signOut();
    }

    protected override onDispatchSuccess(): void {
        Actions.dispatch(new NotificationActions.Warning("Выполнен выход"));
        this.authService.signOut();
        this.tabSyncService.postMessage(new SignOutTabSyncPayload());
    }
}
