import { IEventBus } from "@app/_infrastructure/interfaces/i-event-bus";
import { fromEvent, Subject } from "rxjs";
import { filter } from "rxjs/operators";

export class LocalStorageEventBus<T> implements IEventBus<T> {
    public readonly event$ = new Subject<T>();

    constructor(private readonly name: string) {
        this.subscribeOnMessage();
    }

    private subscribeOnMessage(): void {
        fromEvent<StorageEvent>(window, "storage")
            .pipe(filter((event) => event.key === this.name))
            .subscribe((event) => this.onEvent(event));
    }

    private onEvent(event: StorageEvent): void {
        this.event$.next(JSON.parse(event.newValue!));
    }

    public send(event: T): void {
        localStorage.setItem(this.name, JSON.stringify(event));
    }
}
