import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Output,
} from "@angular/core";
import { TextButtonComponent } from "@app/_infrastructure/components/buttons/text-button/text-button.component";

@Component({
    selector: "app-error-state",
    templateUrl: "./error-state.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TextButtonComponent],
})
export class ErrorStateComponent {
    @HostBinding("class") private readonly hostClass =
        "flex flex-wrap justify-center content-center items-center w-full h-full p-1 bg-white";

    @Output() public readonly refreshEvent = new EventEmitter<void>();

    protected refresh(): void {
        this.refreshEvent.emit();
    }
}
