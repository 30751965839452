import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class LocalStorageLoggerService {
    // keep in sync with logs.html
    private readonly lastIndexKey = "logsLastIndex";

    private getLastIndex(): number {
        const value = localStorage.getItem(this.lastIndexKey);
        const parsedValue = value ? Number.parseInt(value, 10) : 0;
        return parsedValue || 0;
    }

    private setLastIndex(value: number): void {
        return localStorage.setItem(this.lastIndexKey, value.toString());
    }

    public log(text: string): void {
        const logId = this.getLastIndex() + 1;
        this.setLastIndex(logId);
        this.writeLog(text, logId);
    }

    private writeLog(text: string, logId: number): void {
        const key = `log_${logId}`;
        localStorage.setItem(key, text);
    }
}
