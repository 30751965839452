import { BehaviorSubject } from "rxjs";

function defaultValuesAreEqual<T>(a: T, b: T): boolean {
    return a === b;
}

export class OptimizedBehaviorSubject<T> extends BehaviorSubject<T> {
    constructor(
        value: T,
        private readonly valuesAreEqual: (
            a: T,
            b: T,
        ) => boolean = defaultValuesAreEqual,
    ) {
        super(value);
    }

    public override next(value: T): void {
        if (!this.valuesAreEqual(this.value, value)) {
            super.next(value);
        }
    }
}
