import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ProgressComponent } from "@app/_progress/progress.component";

@Component({
    selector: "app-button-loading-indicator",
    templateUrl: "./button-loading-indicator.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatProgressSpinnerModule, NgIf, ProgressComponent],
})
export class ButtonLoadingIndicatorComponent {
    @Input() public loading = false;
    @Input() public rounded = false;
}
