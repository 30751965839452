<app-button-loading-indicator [loading]="loading">
    <button
        class="app-base-text-button"
        type="button"
        mat-raised-button
        [disabled]="disabled || loading"
        (click)="onClick($event)"
    >
        {{ text }}
    </button>
</app-button-loading-indicator>
