import { HttpStatusCode } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { ApiErrorsSummary } from "@app/_api/_models/api-errors.summary";
import { AuthService } from "@app/_auth/services/auth.service";
import { NotificationActions } from "@app/_infrastructure/action-handlers/notification.action-handlers";
import {
    ActionHandler,
    Actions,
    initActionHandlers,
    WithActionHandlers,
} from "ngx-action";

export namespace ApiErrorActions {
    export class Handle {
        constructor(public apiErrorsSummary: ApiErrorsSummary) {}
    }
}

@WithActionHandlers()
@Injectable({ providedIn: "root" })
export class ApiErrorActionHandlers {
    private readonly authService = inject(AuthService);

    constructor() {
        initActionHandlers(this);
    }

    @ActionHandler(ApiErrorActions.Handle)
    private apiErrorHandleActionHandler(action: ApiErrorActions.Handle): void {
        this.handleUnauthorized(action.apiErrorsSummary);
        this.showUnhandledErrorsInNotifications(action.apiErrorsSummary);
    }

    private handleUnauthorized(apiErrorsSummary: ApiErrorsSummary): void {
        if (apiErrorsSummary.httpStatus === HttpStatusCode.Unauthorized) {
            this.authService.signOut();
        }
    }

    private showUnhandledErrorsInNotifications(
        apiErrorsSummary: ApiErrorsSummary,
    ): void {
        apiErrorsSummary.errors
            .filter((error) => !error.handled)
            .forEach((error) => {
                const errorText =
                    (error.message || error.key) ?? "Неизвестная ошибка";
                Actions.dispatch(new NotificationActions.Error(errorText));
                error.handled = true;
            });
    }
}
